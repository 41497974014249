/* eslint max-lines: 0 */
import React from 'react';
import {shape } from 'prop-types';
import tripModelShape from 'data/models/TripModelShape';
import { styled } from '@mui/material/styles';
import { Grid, Select, MenuItem } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import StyledFormControl from 'components/Common/StyledFormControl/StyledFormControl';
import commonUseStyles, { commonStyles } from '../../styles/CommonStyles';
import breakpointsNames from '../../styles/ResponsiveConstants';
import { requestTypeDto } from '../../data/models/tripRequestForm/TripRequestFormModel';
import { localize } from '../../util/Localizer';
import { grades } from '../../redux/constants';
import StyledTextField from '../../components/Common/StyledTextField/StyledTextField';
import { tomorrow, yesterday, minimumDateAllowed } from '../../util/dateUtils';

const StudentItems = styled(Grid)(({ theme }) => ({
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
    '& > div:nth-of-type(1)': {
        ...commonUseStyles.defaultGroupLeft,
        [theme.breakpoints.down(breakpointsNames.md)]: {
            paddingRight: 0
        }
    },
    '& > div:nth-of-type(2)': {
        ...commonUseStyles.defaultGroupCenter,
        [theme.breakpoints.down(breakpointsNames.lg)]: {
            paddingRight: 0
        },
        [theme.breakpoints.down(breakpointsNames.md)]: {
            paddingLeft: 0
        }
    },
    '& > div:nth-of-type(3)': {
        ...commonUseStyles.defaultGroupRight,
        [theme.breakpoints.down(breakpointsNames.lg)]: {
            paddingLeft: 0,
            paddingRight: 15
        },
        [theme.breakpoints.down(breakpointsNames.md)]: {
            paddingRight: 0
        }
    },
    '& > div:nth-of-type(4)': {
        ...commonUseStyles.defaultGroupLeft,
        [theme.breakpoints.down(breakpointsNames.lg)]: {
            paddingLeft: 15,
            paddingRight: 0
        },
        [theme.breakpoints.down(breakpointsNames.md)]: {
            paddingLeft: 0
        }
    },
    '& > div:nth-of-type(5)': {
        ...commonUseStyles.defaultGroupCenter,
        [theme.breakpoints.down(breakpointsNames.lg)]: {
            paddingLeft: 0
        },
        [theme.breakpoints.down(breakpointsNames.md)]: {
            paddingRight: 0
        }
    },
    '& > div:nth-of-type(6)': {
        ...commonUseStyles.defaultGroupRight,
        [theme.breakpoints.down(breakpointsNames.md)]: {
            paddingLeft: 0,
            paddingRight: 0
        }
    }
}));

const GroupInsideLeft = styled(Grid)(() => ({
    ...commonUseStyles.defaultGroupInsideLeft
}));

const GroupInsideRight = styled(Grid)(() => ({
    ...commonUseStyles.defaultGroupInsideRight
}));

const DateInput = styled(DatePicker)(() => ({
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    '& > label': {
        paddingLeft: 12
    },
    '& > div': {
        paddingRight: 12
    },
    '& > div > div': {
        marginLeft: 0,
        maxHeight: 48,
        height: 48,
        borderTopRightRadius: 4
    },
    '& > div > div > button': {
        marginBottom: -10
    },
    '& > div > input': {
        paddingLeft: 12
    }
}));

const FormControl = styled(StyledFormControl)(({ theme }) => ({
    marginTop: theme.spacing(1)
}));

const FormLabel = styled('span')(() => ({
    ...commonUseStyles.customFormLabel
}));

const RequiredLabel = styled('span')(({ theme }) => ({
    color: theme.palette.everDrivenFuchsia
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    ...commonStyles(theme).selectItem
}));

const ErrorTextField = styled(StyledTextField)(({ theme }) => ({
    "& .Mui-error": {
        color: theme.palette.everDrivenFuchsia
    },
    "& .MuiFormHelperText-root": {
        color: theme.palette.everDrivenFuchsia
    }
}));

function TextFieldForDate(props, id, dateErrorMessage, disabled) {
    return <ErrorTextField
    // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        id={id}
        isDisabled={disabled}
        size="small"
        variant="filled"
        autoComplete="disabled"
        fullWidth
        helperText={dateErrorMessage ?? ''}
    />;
}

function StudentInformation({
    classesInputEnabled,
    isEnabledStudentDate,
    studentBirthDateErrorMessage,
    studentEndDateErrorMessage,
    studentStartDateErrorMessage,
    tripModel,
    onUpdateEndDate,
    onUpdateModel: onUpdateStudent,
    onUpdateStartDate,
    onUpdateStudentAge
}) {
    const handleChangeStudent = (evt) => {
        if (evt === null) {
            return false;
        }

        onUpdateStudent(evt);

        return true;
    };

    const handleChangeDate = (evt) => {
        onUpdateStudentAge(evt);

        return true;
    };

    const handleChangeTripStartDate = (evt) => {
        onUpdateStartDate(evt);

        return true;
    };

    const handleChangeTripEndDate = (evt) => {
        onUpdateEndDate(evt);

        return true;
    };

    const renderGrades = () => grades.map(item => (
        <StyledMenuItem key={item.index} value={item.key}>{item.value}</StyledMenuItem>
    ));

    return (
        <StudentItems container>
            <Grid item xs={12} md={6} lg={4}>
                <FormLabel>
                    {localize('tripRequestForm.studentId')}
                    <RequiredLabel>
                        *
                    </RequiredLabel>
                </FormLabel>
                <StyledTextField
                    className={classesInputEnabled}
                    inputProps={{ "data-testid": "studentId" }}
                    isDisabled={requestTypeDto.update === tripModel.requestType}
                    isRequired
                    name="studentId"
                    autoComplete="disabled"
                    value={tripModel.studentId ?? ""}
                    onChange={handleChangeStudent}
                />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                <FormLabel>
                    {localize('tripRequestForm.firstName')}
                    <RequiredLabel>
                        *
                    </RequiredLabel>
                </FormLabel>
                <StyledTextField
                    className={classesInputEnabled}
                    inputProps={{ className: 'qm_block', "data-testid": "studentFirstName" }}
                    isDisabled={requestTypeDto.update === tripModel.requestType}
                    isRequired
                    autoComplete="disabled"
                    name="studentFirstName"
                    value={tripModel.studentFirstName ?? ""}
                    onChange={handleChangeStudent}
                />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                <FormLabel>
                    {localize('tripRequestForm.lastName')}
                    <RequiredLabel>
                        *
                    </RequiredLabel>
                </FormLabel>
                <StyledTextField
                    className={classesInputEnabled}
                    inputProps={{ className: 'qm_block', "data-testid": "studentLastName" }}
                    isDisabled={requestTypeDto.update === tripModel.requestType}
                    isRequired
                    autoComplete="disabled"
                    name="studentLastName"
                    value={tripModel.studentLastName ?? ""}
                    onChange={handleChangeStudent}
                />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                <Grid container>
                    <GroupInsideLeft item xs={6}>
                        <FormLabel>
                            {localize('tripRequestForm.dateofBirth')}
                            <RequiredLabel>
                                *
                            </RequiredLabel>
                        </FormLabel>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                fullWidth
                                required
                                className="qm_block"
                                disabled={isEnabledStudentDate}
                                disableToolbar
                                variant="inline"
                                inputFormat="MM/dd/yyyy"
                                value={tripModel.studentDateOfBirth}
                                maxDate={yesterday()}
                                onChange={handleChangeDate}
                                minDate={minimumDateAllowed()}
                                renderInput={(params) => TextFieldForDate(
                                    params,
                                    "studentDateOfBirth",
                                    studentBirthDateErrorMessage,
                                    isEnabledStudentDate
                                )}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date'
                                }}
                                InputAdornmentProps={{
                                    color: "secondary"
                                }}
                            />
                        </LocalizationProvider>
                    </GroupInsideLeft>
                    <GroupInsideRight item xs={6}>
                        <FormLabel>
                            {localize('tripRequestForm.age')}
                        </FormLabel>
                        <StyledTextField
                            className={classesInputEnabled}
                            inputProps={{ "data-testid": "studentAge" }}
                            isDisabled
                            name="studentAge"
                            value={tripModel.studentAge ?? 0}
                            onChange={handleChangeStudent}
                        />
                    </GroupInsideRight>
                </Grid>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                <FormLabel>
                    {localize('tripRequestForm.grade')}
                </FormLabel>
                <FormControl
                    data-testid="studentGrade"
                    fullWidth size="small"
                    variant="filled"
                >
                    <Select
                        name="studentGrade"
                        labelId="studentGrade"
                        onChange={handleChangeStudent}
                        displayEmpty
                        value={tripModel.studentGrade ?? ""}
                        MenuProps={{
                            disableScrollLock: true
                        }}
                    >
                        {renderGrades()}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                <Grid container>
                    <GroupInsideLeft item xs={6}>
                        <FormLabel>
                            {localize('tripRequestForm.startDate')}
                            {requestTypeDto.new === tripModel.requestType ?
                                <RequiredLabel>
                                *
                                </RequiredLabel>
                                : null
                            }
                        </FormLabel>
	                	<LocalizationProvider dateAdapter={AdapterDateFns}>
		                    <DateInput
		                        fullWidth
		                        disableToolbar
		                        variant="inline"
		                        inputFormat="MM/dd/yyyy"
		                        value={tripModel.tripStartDate}
		                        minDate={tomorrow()}
		                        onChange={handleChangeTripStartDate}
		                        renderInput={(params) => TextFieldForDate(
                                    params,
                                    "tripStartDate",
                                    studentStartDateErrorMessage
                                )}
		                        KeyboardButtonProps={{
		                            'aria-label': 'change date'
		                        }}
		                    />
		                </LocalizationProvider>
                    </GroupInsideLeft>
                    <GroupInsideRight item xs={6}>
                        <FormLabel>
                            {localize('tripRequestForm.endDate')}
                            {requestTypeDto.new === tripModel.requestType ?
                                <RequiredLabel>
                                *
                                </RequiredLabel>
                                : null
                            }
                        </FormLabel>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DateInput
                                fullWidth
                                disableToolbar
                                variant="inline"
                                inputFormat="MM/dd/yyyy"
                                value={tripModel.tripEndDate}
                                minDate={!tripModel.tripStartDate ? tomorrow() : tripModel.tripStartDate}
                                onChange={handleChangeTripEndDate}
		                        renderInput={(params) => TextFieldForDate(
                                    params,
                                    "tripEndDate",
                                    studentEndDateErrorMessage
                                )}
		                        KeyboardButtonProps={{
		                            'aria-label': 'change date'
		                        }}
                            />
                        </LocalizationProvider>
                    </GroupInsideRight>
                </Grid>
            </Grid>
        </StudentItems>
    );
}

StudentInformation.defaultProps = {
    tripModel: null
};

StudentInformation.propTypes = {
    tripModel: shape(tripModelShape)
};

export default StudentInformation;
