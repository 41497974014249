import { arrayOf, bool, string, number, date, oneOfType } from 'prop-types';

const tripModelShape = {
    tripStartDate: oneOfType([
        string,
        date
    ]),
    tripEndDate: oneOfType([
        string,
        date
    ]),
    studentDateOfBirth: oneOfType([
        string,
        date
    ]),
    studentId: string,
    requestType: string,
    correlationId: string,
    studentFirstName: string,
    studentLastName: string,
    studentAge: oneOfType([
        string,
        number
    ]),
    studentGrade: string,
    studentAddressLine1: string,
    studentAddressLine2: string,
    studentAddressCity: string,
    studentAddressZipCode: string,
    studentAddressState: string,
    studentAddressLatitude: oneOfType([
        string,
        number
    ]),
    studentAddressLongitude: oneOfType([
        string,
        number
    ]),
    parent1FirstName: string,
    parent1Relationship: string,
    parent1MobilePhone: string,
    parent1AltPhone: string,
    parent1EmailAddress: string,
    parent1AppAccess: bool,
    accountId: string,
    accountNumber: string,
    accountName: string,
    amPickupLocation: string,
    pmDropOffLocation: string,
    transportationEquipmentNeeds: arrayOf(string),
    comments: string,
    populationTypes: arrayOf(string),
    pickupLocationAddressLine1: string,
    pickupLocationAddressLine2: string,
    pickupLocationAddressCity: string,
    pickupLocationAddressZipCode: string,
    pickupLocationAddressState: string,
    pickupLocationAddressLatitude: oneOfType([
        string,
        number
    ]),
    pickupLocationAddressLongitude: oneOfType([
        string,
        number
    ]),
    dropOffLocationAddressLine1: string,
    dropOffLocationAddressLine2: string,
    dropOffLocationAddressCity: string,
    dropOffLocationAddressZipCode: string,
    dropOffLocationAddressState: string,
    dropOffLocationAddressLatitude: oneOfType([
        string,
        number
    ]),
    dropOffLocationAddressLongitude: oneOfType([
        string,
        number
    ]),
    schoolName: string,
    schoolAddressLine1: string,
    schoolAddressLine2: string,
    schoolAddressCity: string,
    schoolAddressZipCode: string,
    schoolAddressState: string,
    schoolPhone: string,
    schoolMonday: bool,
    schoolTuesday: bool,
    schoolWednesday: bool,
    schoolThursday: bool,
    schoolFriday: bool,
    schoolSaturday: bool,
    schoolSunday: bool,
    schoolArrivalMonday: oneOfType([
        string,
        date
    ]),
    schoolArrivalTuesday: oneOfType([
        string,
        date
    ]),
    schoolArrivalWednesday: oneOfType([
        string,
        date
    ]),
    schoolArrivalThursday: oneOfType([
        string,
        date
    ]),
    schoolArrivalFriday: oneOfType([
        string,
        date
    ]),
    schoolArrivalSaturday: oneOfType([
        string,
        date
    ]),
    schoolArrivalSunday: oneOfType([
        string,
        date
    ]),
    schoolPickupMonday: oneOfType([
        string,
        date
    ]),
    schoolPickupTuesday: oneOfType([
        string,
        date
    ]),
    schoolPickupWednesday: oneOfType([
        string,
        date
    ]),
    schoolPickupThursday: oneOfType([
        string,
        date
    ]),
    schoolPickupFriday: oneOfType([
        string,
        date
    ]),
    schoolPickupSaturday: oneOfType([
        string,
        date
    ]),
    schoolPickupSunday: oneOfType([
        string,
        date
    ]),
    schoolArrivalInstructions: string,
    schoolAddressLatitude: oneOfType([
        string,
        number
    ]),
    schoolAddressLongitude: oneOfType([
        string,
        number
    ]),
    contact1FirstName: string,
    contact1MobilePhone: string,
    contact1AltPhone: string,
    contact1Relationship: string,
    contact1EmailAddress: string,
    contact1AppAccess: bool,
    contact2FirstName: string,
    contact2MobilePhone: string,
    contact2AltPhone: string,
    contact2Relationship: string,
    contact2EmailAddress: string,
    contact2AppAccess: bool
};

export default tripModelShape;