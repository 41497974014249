import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import withTheme from '@mui/styles/withTheme';

import TripRequestFormContent from '../../../controls/TripRequestForm/TripRequestFormContent';
import withRouter from '../../../util/customHooks/withRouter';

import {
    onAccountEmailValidation,
    onOpenSubmitModal,
    startTRF
} from '../../workers/tripRequestFormWorker';
import { onSaveDraft } from '../../workers/tripRequestFormDraftWorker';
import { setDeleteDraftModalStatus } from '../../actions/tripRequestFormActions';

const mapStateToProps = state => {
    const { TripRequestFormReducers } = state;

    return {
        currentSibling: TripRequestFormReducers.currentSibling,
        errorMessages: TripRequestFormReducers.errorMessages,
        isDraftForSave: TripRequestFormReducers.isDraftForSave,
        isDraftSelected: TripRequestFormReducers.isDraftSelected,
        isEnabledSubmit: TripRequestFormReducers.isEnabledSubmit,
        selectedAccountHasNoEmail: TripRequestFormReducers.selectedAccountHasNoEmail,
        tripModel: TripRequestFormReducers.tripModel
    };
};

const TripRequestFormContentContainer = withRouter(
    connect(mapStateToProps,
        {
            onAccountEmailValidation,
            onOpenSubmitModal,
            onSaveDraft,
            setDeleteDraftModalStatus,
            startTRF
        }
    )(withTheme(injectIntl(TripRequestFormContent)))
);

export default TripRequestFormContentContainer;
