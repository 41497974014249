import React, { useEffect, useState } from 'react';
import { Paper, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { shape, func} from 'prop-types';
import tripModelShape from 'data/models/TripModelShape';
import commonUseStyles from '../../styles/CommonStyles';
import GeneralTripInformationContainer from '../../redux/containers/tripRequestForm/GeneralTripInformationContainer';
import StudentInformationContainer from '../../redux/containers/tripRequestForm/StudentInformationContainer';
import AddressInformationContainer from '../../redux/containers/tripRequestForm/AddressInformationContainer';
import SchoolInformationContainer from '../../redux/containers/tripRequestForm/SchoolInformationContainer';
import StyledButton from '../../components/Common/StyledButton/StyledButton';
import StyledTypography from '../../components/Common/StyledTypography/StyledTypography';
import EmergencyContactInfoContainer from '../../redux/containers/tripRequestForm/EmergencyContactInfoContainer';
import { initChangeDetection } from '../../util/formUtil';
import { localize } from '../../util/Localizer';

const useStyles = makeStyles(theme => ({
    container: {
        position: 'relative',
        borderRadius: '6px 6px 6px 6px',
        marginBottom: 20,
        paddingLeft: 25,
        paddingRight: 25,
        marginTop: 20,
        backgroundColor: theme.palette.offWhite,
        '& .MuiTextField-root': {
            marginTop: theme.spacing(1),
            color: theme.palette.black,
            fontWeight: 'bold'
        },
        '& .h6': {
            fontWeight: 'bold !important'
        }
    },
    headerSection: {
        ...commonUseStyles.headerForm,
        borderRadius: '6px 6px 0px 0px'
    },
    groupTitle: {
        marginBottom: -10
    },
    footerSection: {
        ...commonUseStyles.header,
        '& > h5': {
            fontWeight: 'bold !important'
        }
    },
    formControl: { marginTop: theme.spacing(6) },
    btnSubmit: {
        width: 400,
        backgroundColor: theme.palette.everDrivenDarkGreen
    },
    errorMessage: { color: theme.palette.everDrivenFuchsia },
    btnDeleteDraft: {
        width: 400,
        backgroundColor: theme.palette.white,
        color: theme.palette.black,
        borderColor: theme.palette.black,
        border: 2,
        borderStyle: 'solid',
        '&:hover' : {
            color: theme.palette.white,
            backgroundColor: theme.palette.everDrivenDarkBlue
        },
        '&.Mui-disabled': {
            borderColor: 'transparent'
        }
    },
    btnSaveDraft: {
        width: 400,
        backgroundColor: theme.palette.white,
        borderColor: theme.palette.everDrivenDarkGreen,
        border: 2,
        borderStyle: 'solid',
        color: theme.palette.everDrivenDarkGreen,
        '&:hover' : {
            color: theme.palette.white,
            borderColor: theme.palette.everDrivenDarkBlue,
            backgroundColor: theme.palette.everDrivenDarkBlue
        },
        '&.Mui-disabled': {
            borderColor: 'transparent'
        }
    },
    inputEnabled: {
        '& > div > input': {
            borderTopLeftRadius: 4,
            borderTopRightRadius: 4
        }
    },
    buttonContent: {
        display: 'flex',
        justifyContent: 'right',
        marginTop: theme.spacing(6),
        borderTop: 'groove',
        paddingTop: 20,
        '&> button:nth-of-type(2)': {
            marginLeft: 10
        },
        '&> :last-child': {
            marginLeft: 10
        }
    }
}));

function TripRequestFormContent({
    currentSibling,
    errorMessages,
    isDraftForSave,
    isDraftSelected,
    isEnabledSubmit,
    selectedAccountHasNoEmail,
    onOpenSubmitModal,
    onAccountEmailValidation,
    onSaveDraft,
    setDeleteDraftModalStatus,
    startTRF,
    tripModel
}) {
    const classes = useStyles();

    const [ form, setForm ] = useState(document.getElementById("trfForm"));

    useEffect(() => {
        if (form) {
            initChangeDetection(form);
        } else {
            setForm(document.getElementById("trfForm"));
        }
    },
    [form]);

    const onClickBtnSubmit = (event) => {
        event.preventDefault();

        if (onAccountEmailValidation()) {
            onOpenSubmitModal(currentSibling);
        }
    };

    const onClickDeleteDraft = (event) => {
        event.preventDefault();
        setDeleteDraftModalStatus(true);
    };

    const onClickSaveDraft = (event) => {
        event.preventDefault();
        onSaveDraft();
    };

    const handleInteraction = () => {
        if (!tripModel.correlationId) {
            startTRF();
        }
    };

    const classesHeader = `memberCard ${classes.headerSection}`;
    const classesFooter = `memberCard ${classes.footerSection}`;

    return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div onClick={handleInteraction} onKeyUp={handleInteraction}>
            <Paper square className={classes.container} >
                <form autoComplete="disabled" onSubmit={onClickBtnSubmit} id="trfForm">
                    <div className={classesHeader}>
                        <StyledTypography
                            variant="h5"
                            className={classes.groupTitle}
                        >
                            {localize('tripRequestForm.studentDetails', { number: currentSibling.toString() })}
                        </StyledTypography>
                    </div>
                    <StudentInformationContainer classesInputEnabled={classes.inputEnabled} />

                    <div className={classesHeader}>
                        <StyledTypography
                            variant="h5"
                            className={classes.groupTitle}
                        >
                            {localize('tripRequestForm.homeAddresses')}
                        </StyledTypography>
                    </div>
                    <AddressInformationContainer />

                    <div className={classesHeader}>
                        <StyledTypography
                            variant="h5"
                            className={classes.groupTitle}
                        >
                            {localize('tripRequestForm.generalTripInformation')}
                        </StyledTypography>
                    </div>
                    <GeneralTripInformationContainer />

                    <div className={classesHeader}>
                        <StyledTypography
                            variant="h5"
                            className={classes.groupTitle}
                        >
                            {localize('tripRequestForm.schoolInformation')}
                        </StyledTypography>
                    </div>
                    <SchoolInformationContainer
                        classesInputEnabled={classes.inputEnabled}
                    />

                    <div className={classesHeader}>
                        <StyledTypography
                            variant="h5"
                            className={classes.groupTitle}
                        >
                            {localize('tripRequestForm.emergencyContactInfo')}
                        </StyledTypography>
                    </div>
                    <EmergencyContactInfoContainer />

                    <Grid container className={classes.formControl}>
                        <Grid item xs={12}>
                            {selectedAccountHasNoEmail ?
                                (<span data-testid="errorMessageNoEmail" className={classes.errorMessage}>
                                    {localize('tripRequestForm.noAccountEmailSubmitPart1')}
                                    <a href="https://rrts1614707543.atlassian.net/servicedesk/customer/portal/3/group/17/create/58">
                                        {localize('tripRequestForm.noAccountEmailSubmitPart2')}
                                    </a>
                                </span>)
                                : null
                            }
                            {errorMessages.length > 0 ?
                                (<ul>
                                    {errorMessages.map(item => <li key={item}
                                        data-testid="errorMessage"
                                        className={classes.errorMessage}>{item}</li>)}
                                </ul>)
                                : null
                            }
                        </Grid>
                        <Grid item xs={12} className={classes.buttonContent}>
                            {isDraftSelected && (
                                <StyledButton
                                    className={classes.btnDeleteDraft}
                                    testId="deleteDraft"
                                    type="button"
                                    onClick={onClickDeleteDraft}
                                >
                                    {localize('tripRequestForm.deleteDraft')}
                                </StyledButton>
                            )}
                            <StyledButton
                                className={classes.btnSaveDraft}
                                isDisabled={!isDraftForSave}
                                testId="saveDraft"
                                type="button"
                                onClick={onClickSaveDraft}
                            >
                                {localize('tripRequestForm.saveDraft')}
                            </StyledButton>
                            <StyledButton
                                className={classes.btnSubmit}
                                isDisabled={isEnabledSubmit}
                                testId="submitTRF"
                                type="submit"
                            >
                                {localize('tripRequestForm.submit')}
                            </StyledButton>
                        </Grid>
                    </Grid>
                    <div className={classesFooter}> </div>
                </form>
            </Paper>
        </div>
    );
}

TripRequestFormContent.defaultProps = {
    startTRF: () => null,
    tripModel: null
};

TripRequestFormContent.propTypes = {
    tripModel: shape(tripModelShape),
    startTRF: func
};

export default TripRequestFormContent;
